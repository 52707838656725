import React from "react"
import { graphql } from "gatsby"

import Layout from "components/layout/layout"
import SEO from "components/seo"
import Hero from "components/hero"
import Contact_Section from "components/contact_section"
import { seoDataFromPage } from "components/utils/data-formatting"

import c from "components/contact_section.module.scss"

const ContactPage = ({ pageContext, data: queryData }) => {
  const data = queryData.wordpressPage.acf.contact
  const meta = seoDataFromPage(queryData.wordpressPage)

  return (
    <Layout>
      <SEO title={pageContext.title} meta={meta} />
      <Hero
        data={{ bg: data.bg, title: data.hero_title, text: data.hero_text }}
        extraClass={c.hero}
      />
      <Contact_Section data={data} />
    </Layout>
  )
}

export default ContactPage

// add page query
export const pageQuery = graphql`
  query($wordpress_id: Int!) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      acf {
        contact {
          bg {
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          hero_title
          hero_text
          contactinfo_title
          form_image {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          address
          email
          phone
          title
          wordpress_fields {
            label
            type
            width
            required
          }
          btn_label
        }
      }
      ...SEOData
    }
  }
`
